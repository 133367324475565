import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Axios from "axios";
import Constants from "./Constants";
import ScrollAnimation from "react-animate-on-scroll";
import renderHTML from "react-render-html";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default class Courses extends Component {
  state = {
    label: [],
    courses: {},
    // data: [],
    specification: {},
    description: "",
  };
  componentDidMount() {
    window.scrollTo(0,0);
    // console.log(this.props);
    // let data = this.props.location.state;
    let id = this.props.match.params.id;
    Axios.get(Constants.getUrls.lablebycategory + id).then((res) => {
      // console.log(res.data);
      this.setState(
        {
          courses: res.data,
          // data,
          // description: res.data.course[0].specification
        },
        () => {
          console.log("courses", this.state.courses);
        }
      );
    });

    console.log(this.props);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      // alert("some");
      let id = this.props.match.params.id;
      Axios.get(Constants.getUrls.lablebycategory + id).then((res) => {
        // console.log(res.data);
        this.setState(
          {
            courses: res.data,
            // description: res.data.course[0].specification
          },
          () => {
            // console.log(this.state.courses);
          }
        );
      });
    }
  }
  openModal(id) {
    // console.log(name);

    this.setState(
      {
        // visible : true,
        specification: this.state.courses.find((element) => element.id === id),
      },
      () => {
        console.log(this.state.specification);
      }
    );
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }
  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <div
          className="breadcumb-area bg-img"
          style={{
            backgroundImage:
              "url(https://upload.wikimedia.org/wikipedia/commons/7/77/Medlibrary.jpg)",
            backgroundPosition: "center",
          }}
        >
          <div className="bradcumbContent">
            <h2>Our Courses</h2>
          </div>
        </div>

        <div className="top-popular-courses-area mt-50 section-padding-100-70">
          <div className="container">
            {/* <ScrollAnimation
              animateIn="fadeInUp"
              animationInDuration={1800}
              animateOnce={true}
              isVisible={true}
            > */}
              <div className="row">
                <div className="col-12">
                  <div
                    className="section-heading text-center mx-auto wow " data-aos="fade-up"
                    // data-wow-delay="300ms"
                  >
                    {/* <span>The Best</span> */}

                    <h5
                      style={{
                        fontSize: "30px",
                        fontWeight: "bold",
                        marginBottom: "20px",
                        marginTop: "-20px",
                      }}
                    >
                      {this.state.courses.sub_title}
                    </h5>

                    <p style={{ fontWeight: "bold", marginTop: "-15px" }}>
                      "{this.state.courses.description}"
                    </p>
                    {/* <h5>
                      {renderHTML(
                        this.state.courses.course
                          ? this.state.courses.course[0].specification
                          : null
                      )}
                    </h5> */}
                    <p style={{ textAlign: "left" }}>
                      {this.state.courses.specification
                        ? renderHTML(this.state.courses.specification)
                        : "Not Available"}
                    </p>
                  </div>
                </div>
              </div>
            {/* </ScrollAnimation> */}
            <ScrollAnimation
              animateIn="fadeInUp"
              // animateOut="fadeOut"
              animateOnce={true}
              animationInDuration={1800}
              // animationOutDuration={1800}
              isVisible={true}
            >
              {/* <div className="row">
                {this.state.courses
                  ? this.state.courses.map((singleCourse, index) => (
                      <div className="col-12 col-lg-6">
                        <div
                          className="single-top-popular-course d-flex align-items-center flex-wrap mb-30 wow fadeInUp"
                          data-wow-delay="400ms"
                        >
                          <div className="popular-course-content">
                            <h5>{singleCourse.name} </h5>
                            <div className="course-ratings">
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i className="fa fa-star" aria-hidden="true"></i>
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <p>{singleCourse.description}</p>

                            <Link
                              to={{
                                pathname: "/courseDetail",
                                state: {
                                  name: singleCourse.name,
                                  specification: singleCourse.specification,
                                  id: singleCourse.id
                                }
                              }}
                              className="btn Academy-btn btn-sm"
                              onClick={() => this.openModal(singleCourse.id)}
                            >
                              See More
                            </Link>
                          </div>
                          <div className="popular-course-thumb bg-img">
                            <img src={singleCourse.image} width="100%" />
                          </div>
                        </div>
                      </div>
                    ))
                  : "Courses Not Available"}
              </div> */}
            </ScrollAnimation>
          </div>
        </div>

        {/* <ScrollAnimation
          animateIn="fadeInUp"
          // animateOut="fadeOut"
          animationInDuration={1800}
          animateOnce={true}
          // animationOutDuration={1800}
          isVisible={true}
        > */}
          <div className="call-to-action-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="cta-content d-flex align-items-center justify-content-between flex-wrap" data-aos="fade-up">
                    <h3>Do you want to enroll at our Academy? Get in touch!</h3>
                    <a href="/contact" className="btn Academy-btn">
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </ScrollAnimation> */}

        <Footer />
      </div>
    );
  }
}
