import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "./assets/img/core-img/logo.png";
import Axios from "axios";
import Constants from "./Constants";

export default class Navbar extends Component {
  state = {
    label: [],
    active: false
  };
  componentDidMount() {
    Axios.get(Constants.getUrls.labels).then(res => {
      console.log(res.data);
      this.setState({
        label: res.data
      });
    });
  }
  handleClick = () => {
    // alert();
    const { active } = this.state;
    this.setState({ active: !active });
  };
  render() {
    return (
      // <div className="is-sticky" style={{ height: "85px" }}>
      <div className="Academy-main-menu">
        <div className="classy-nav-container breakpoint-off">
          {/* <div className="container"> */}
          <nav
            className="classy-navbar justify-content-between"
            id="academyNav"
          >
            <div className="classy-navbar-toggler" onClick={this.handleClick}>
              <span className="navbarToggler">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>

            <div
              className="classy-menu"
              style={{ left: this.state.active ? 0 : "-460px" }}
            >
              <div
                className="classycloseIcon"
                onClick={() => {
                  this.setState({ active: false });
                }}
              >
                <div className="cross-wrap">
                  <span className="top"></span>
                  <span className="bottom"></span>
                </div>
              </div>

              <div className="classynav">
                <div className="Academy-logo">
                  <Link to="/">
                    <img
                      src={logo}
                      width="100px"
                      style={{ marginRight: "30px" }}
                    />
                  </Link>
                </div>
                <div class="classySub" style={{ marginLeft: "45px" }}>
                  <ul>
                    <NavLink
                      to="/"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      exact={true}
                      activeClassName="selected"
                      activeStyle={{
                        color: "#fcd94b"
                      }}
                      className="mainTab"
                      onClick={() => {
                        this.setState({ active: false });
                      }}
                    >
                      Home
                    </NavLink>
                    {/* <a href=""> */}
                    <NavLink
                      to="#"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      activeClassName="selected"
                      activeStyle={
                        {
                          // color: "#fcd94b"
                        }
                      }
                      className={"mainTab"}
                    >
                      <div
                        className="dropdown"
                      >
                        <span className="courses_web">Courses</span>
                        <div className="dropdown-content">
                          {this.state.label.map(single => (
                            <Link
                              to={{
                                pathname: "/course/" + single.id,
                                state: {
                                  name: single.label
                                }
                              }}
                              onClick={() => {
                                this.setState({ active: false });
                              }} className="drop_downlink"
                              style={{ color: "black", display: "block",paddingLeft:"20px" }}
                            >
                              {single.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </NavLink>
                    {/* </a> */}
                    <NavLink
                      to="/teacher"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      activeClassName="selected"
                      activeStyle={{
                        color: "#fcd94b"
                      }}
                      className={"mainTab"}
                      onClick={() => {
                        this.setState({ active: false });
                      }}
                    >
                      Teachers Profile
                    </NavLink>
                    {/* <a href=""> */}
                    <NavLink
                      to="#"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      activeClassName="selected"
                      activeStyle={
                        {
                          // color: "#fcd94b"
                        }
                      }
                      className={"mainTab"}
                    >
                      <div className="dropdown">
                        <span className="courses_web"> Class Schedule</span>
                        <div className="dropdown-content">
                          {this.state.label.map(single => (
                            <Link
                              to={{
                                pathname: "/batches/" + single.id,
                                state: {
                                  name: single.label
                                }
                              }}
                              onClick={() => {
                                this.setState({ active: false });
                              }} className="drop_downlink"
                              // to={{ pathname: "/batches/" + single.id }}
                              style={{ color: "black", display: "block",paddingLeft:"20px" }}
                            >
                              {single.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </NavLink>
                    {/* </a> */}
                    <NavLink
                      to="#"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      activeClassName="selected"
                      activeStyle={
                        {
                          // color: "#fcd94b"
                        }
                      }
                      className={"mainTab"}
                    >
                      <div className="dropdown">
                        <span className="courses_web"> Student Corner</span>
                        <div className="dropdown-content">
                          <Link to="/announce" style={{paddingLeft:"20px",paddingRight:"26px"}} className="drop_downlink">Announcement </Link>
                          <Link to="/gallery" style={{paddingLeft:"20px",paddingRight:"80px"}} className="drop_downlink">Gallery </Link>
                        </div>
                      </div>
                    </NavLink>
                    {/* <NavLink
                    to="/result"
                    style={{ paddingRight: "15px", fontSize: "15px" }}
                    activeClassName="selected"
                    activeStyle={{
                      color: "#fcd94b"
                    }}
                  >
                    Results
                  </NavLink> */}
                    {/* <NavLink
                      to="/gallery"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      activeClassName="selected"
                      activeStyle={{
                        color: "#fcd94b"
                      }}
                    >
                      Gallery
                    </NavLink> */}
                    <NavLink
                      to="#"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      activeClassName="selected"
                      activeStyle={{
                        color: "#fcd94b"
                      }}
                      className={"mainTab"}
                    >
                      <div className="dropdown">
                        <span className="courses_web"> Achievers</span>
                        <div className="dropdown-content">
                          {this.state.label.map(single => (
                            <Link
                              to={{
                                pathname: "/achievers/" + single.id,
                                state: {
                                  name: single.label
                                }
                              }}
                              onClick={() => {
                                this.setState({ active: false });
                              }} className="drop_downlink"
                              // to={{ pathname: "/batches/" + single.id }}
                              style={{ color: "black", display: "block",paddingLeft:"20px" }}
                            >
                              {single.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/contact"
                      style={{ paddingRight: "15px", fontSize: "15px" }}
                      activeClassName="selected"
                      activeStyle={{
                        color: "#fcd94b"
                      }}
                      className={"mainTab"}
                      onClick={() => {
                        this.setState({ active: false });
                      }}
                    >
                      Contact
                    </NavLink>
                  </ul>
                </div>
              </div>
            </div>

            <div className="calling-info">
              <div className="call-center">
                {/* <a href="tel:7447799773">
                  <i className="icon-telephone-2"></i>
                  <span>(+91) 7447799773</span>
                </a> */}
                <a
                  href="tel:7447799773"
                  onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '7447799773', 0);"
                  style={{ fontWeight: "700" }}
                >
                  <i className="icon-telephone-2"></i>
                  <span>(+91) 7447799773</span>
                </a>
              </div>
            </div>
          </nav>
          {/* </div> */}
        </div>
      </div>
      // </div>
    );
  }
}
