import React, { Component } from "react";
export default class Header extends Component {
  render() {
    return (
      <div>
        {/* <header class="header-area">
          <div className="top-header">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 h-100">
                  <div className="header-content h-100 d-flex align-items-center justify-content-between">
                    <div className="Academy-logo">
                      <a href="index.html">
                        <img src={logo} width="100px" />
                      </a>
                    </div>
                    <div className="login-content">
                      <Link
                        to="/contact"
                        // style={{ color: "black" }}
                        className="btn Academy-btn "
                      >
                        Apply Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header> */}
        {/* <div className="Academy-main-menu">
          <div className="is-sticky">
            <div className="classy-nav-container breakpoint-off">
              <div className="container">
                <nav
                  className="classy-navbar justify-content-between"
                  id="academyNav"
                >
                  <div className="classy-navbar-toggler">
                    <span className="navbarToggler">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </div>

                  <div className="classy-menu">
                    <div className="classycloseIcon">
                      <div className="cross-wrap">
                        <span className="top"></span>
                        <span className="bottom"></span>
                      </div>
                    </div>

                    <div className="classynav">
                      <ul>
                        <NavLink
                          to="/"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          exact={true}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Home
                        </NavLink>
                        <NavLink
                          to="/about"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          About
                        </NavLink>
                        <NavLink
                          to="/course"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Course
                        </NavLink>
                        <NavLink
                          to="/batches"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Batches
                        </NavLink>
                        <NavLink
                          to="/teacher"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Staff
                        </NavLink>
                        <NavLink
                          to="/result"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Results
                        </NavLink>
                        <NavLink
                          to="/gallery"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Gallery
                        </NavLink>
                        <NavLink
                          to="/achievers"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Achievers
                        </NavLink>
                        <NavLink
                          to="/contact"
                          style={{ paddingRight: "12px", fontSize: "17px" }}
                          activeClassName="selected"
                          activeStyle={{
                            color: "#fcd94b"
                          }}
                        >
                          Contact
                        </NavLink>
                      </ul>
                    </div>
                  </div>

                  <div className="calling-info">
                    <div className="call-center">
                      <a href="tel:+654563325568889">
                        <i className="icon-telephone-2"></i>
                        <span>(+91) 7447799773</span>
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
     */}
      </div>
    );
  }
}
