/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { Component } from "react";
import Header from "./Header";
import Axios from "axios";
import Constants from "./Constants";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import Modal from "react-awesome-modal";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import Footer from "./Footer";
import Navbar from "./Navbar";
import About from "./About";
// import Constants from "./Constants";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default class Home extends Component {
  state = {
    label: [],
    slider: [],
    courses: [],
    teams: [],
    announce: [],
    gallery: [],
    batches: [],
    specification: {},
    detail: {},
    first_name: "",
    last_name: "",
    course: "",
    mobile: "",
    messages: "",
    popup: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.setState({
    //   visible: true
    // });
    Axios.get(Constants.getUrls.homeImages).then((res) => {
      // console.log(res.data)
      this.setState({
        slider: res.data,
      });
    });
    Axios.get(Constants.getUrls.labels).then((res) => {
      //   console.log(res);
      this.setState({
        label: res.data,
      });
    });
    Axios.get(Constants.getUrls.courses).then((res) => {
      //   console.log(res);
      this.setState({
        courses: res.data,
      });
    });
    Axios.get(Constants.getUrls.teams).then((res) => {
      console.log(res);
      this.setState({
        teams: res.data,
      });
    });
    Axios.get(Constants.getUrls.batches).then((res) => {
      // console.log(res);
      this.setState({
        batches: res.data,
      });
    });
    Axios.get(Constants.getUrls.announce).then((res) => {
      console.log(res.data);
      this.setState({
        announce: res.data,
      });
    });
    Axios.get(Constants.getUrls.popup).then((res) => {
      console.log(res.data);
      this.setState({
        popup: res.data,
      });
    });
  }
  openDetail(id) {
    this.setState(
      {
        visible: true,
        specification: this.state.teams.find((element) => element.id === id),
      },
      () => {
        console.log(this.state.specification.description);
      }
    );
  }
  // openDetail(id) {
  //   // console.log(name);

  //   this.setState(
  //     {
  //       visible: true,
  //       detail: this.state.batches.find((element) => element.id === id),
  //     },
  //     () => {
  //       // console.log(this.state.detail)
  //     }
  //   );
  // }

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <marquee
          width="100%"
          direction="left"
          height="37px"
        // style={{ textAlign: "center" }}
        >
          {this.state.announce
            ? this.state.announce.map((singletext) => (
              <li
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  display: "inline-block",
                  marginRight: "16px",
                }}
              >
                <i class="fa fa-circle" style={{ marginRight: "5px" }}></i>{" "}
                {singletext.announcement}
              </li>
            ))
            : null}
        </marquee>
        <section>
          {/* <button
            style={{
              borderColor: "white",
              borderWidth: 0,
              width: 0,
              textDecoration: "none"
            }}
            // style={{ marginTop: "10px" }}
            onClick={() => this.openModal()}
          ></button> */}

          <Modal
            visible={this.state.visible}
            width="900"
            // height="500"
            style={{ marginTop: "-80px" }}
            backgroundColor="white"
            onClickAway={() => this.closeModal()}
            className="modal_pop"
          >
            <div>
              <CarouselProvider
                naturalSlideWidth={200}
                naturalSlideHeight={112}
                totalSlides={this.state.popup ? this.state.popup.length : null}
                isPlaying={true}
                interval={3000}
              >
                <Slider>
                  {this.state.popup
                    ? this.state.popup.map((singleimage, index) => (
                      <Slide index={index.length}>
                        <img
                          alt={"singleimage"}
                          src={Constants.imgUrl + singleimage.img}
                          width="100%" className="modal_popup"
                        />
                      </Slide>
                    ))
                    : null}

                  {/* <Slide index={1}>
                    <img
                      src="https://images.unsplash.com/photo-1506377872008-6645d9d29ef7?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
                      width="100%"
                    />
                  </Slide> */}
                </Slider>
              </CarouselProvider>

              {/* <button href="" onClick={() => this.closeModal()}>
                
              </button> */}
            </div>
          </Modal>
        </section>
        <section className="home-slider owl-carousel">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={this.state.slider ? this.state.slider.length : null}
            isPlaying={true}
            interval={3000}
          >
            <Slider>
              {this.state.slider
                ? this.state.slider.map((singleSlide, index) => (
                  <Slide index={0}>
                    <div
                      className="slider-item"
                    // style={{backgroundImage:"url("+singleSlide.image +")"}}
                    >
                      <img
                        src={Constants.imgUrl + singleSlide.image}
                        width="100%"
                        // style={{ height: "600px" }}
                        alt=""
                      />
                      {/* <div className="overlay"></div> */}
                      <div className="container">
                        <div
                          className="row no-gutters slider-text align-items-center justify-content-start"
                          data-scrollax-parent="true"
                          style={{
                            position: "absolute",
                            top: "0%",
                            left: "10%",
                          }}
                        >
                          <div className="">
                            {/* <Animated
                                animationIn="fadeInUp"
                                animationInDuration={1800}
                                isVisible={true}
                                // animateOnce={false}
                                animateOnce={true}
                              > */}
                            {/* <h2
                                  className=""
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    margin: "40px",
                                  }}
                                >
                                  {singleSlide.description}
                                </h2> */}
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            ></p>
                            {/* </Animated> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                ))
                : null}
            </Slider>
          </CarouselProvider>
        </section>

        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={true}
        > */}
        <div>
          <About /></div>
        <div className="top-popular-courses-area section-padding-100-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  data-aos="fade-up"
                  className="section-heading text-center mx-auto"
                >
                  {/* <span>The Best</span> */}
                  <h3>Our Courses</h3>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.label
                ? this.state.label.slice(0, 4).map((singleLabel, index) => (
                  <div className="col-12 col-lg-6" data-aos="zoom-in">
                    <div
                      className="single-top-popular-course d-flex align-items-center flex-wrap mb-30 wow"
                    >
                      <div className="popular-course-content">
                        <h4>{singleLabel.sub_title} </h4>
                        {/* <span>By Simon Smith   |  March 18, 2018</span> */}
                        {/* <div className="course-ratings">
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                              </div> */}
                        <p>{singleLabel.description}</p>
                        {/* <a href="#" className="btn Academy-btn btn-sm">
                        See More
                      </a> */}
                        <Link
                          to={{
                            pathname: "/course/" + singleLabel.id,
                            state: {
                              name: singleLabel.name,
                              specification: singleLabel.specification,
                              id: singleLabel.id,
                            },
                          }}
                          className="btn Academy-btn btn-sm"
                        // onClick={() => (singleLabel.id)}
                        >
                          See More
                        </Link>
                        {/* <div className="circle"></div> */}
                      </div>

                      <div
                        className="popular-course-thumb bg-img"
                      // style="background-image: url(img/bg-img/pc-1.jpg);"
                      >
                        <img
                          alt={singleLabel.name}
                          src={Constants.imgUrl + singleLabel.img}
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </div>
        </div>
        {/* </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={true}
        >
          <div
            style={{
              textAlign: "center",
              padding: "0px",
              marginTop: "50px",
              marginBottom: "30px"
            }}
          >
            <h3
              style={{
                // fontSize: "104px",
                marginBottom: 0,
                fontWeight: 600,
                fontSize: "34px"
              }}
            >
              Our Faculties
            </h3>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={true}
        >
          <section className="ftco-section bg-light">
            <div className="container-fluid px-4">
              <div className="row">
                {this.state.teams
                  ? this.state.teams.slice(0, 4).map((singleTeam, index) => (
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div
                          className="single-teachers-area text-center mb-100 wow fadeInUp"
                          data-wow-delay="400ms"
                        >
                          <div className="teachers-thumbnail">
                            <img
                              src={singleTeam.image}
                              width="100%"
                              // height="350px"
                              style={{ height: "302px" }}
                            />
                          </div>
                          <div className="teachers-info mt-30">
                            <h5>{singleTeam.name}</h5>
                            {/* <span>{singleTeam.designation}</span> */}
        {/* </div> */}
        {/* <button
                            className=" btn Academy-btn btn-sm"
                            style={{ marginTop: "10px" }}
                          >
                            Know More
                          </button> */}
        {/* // </div> */}
        {/* <section>
                          <input
                            type="button"
                            value="Know More"
                            className="btn Academy-btn btn-sm"
                            style={{ marginTop: "-110px", marginLeft: "80px" }}
                            onClick={() => this.openDetail(singleTeam.id)}
                          />

                          <Modal
                            visible={this.state.visible}
                            width="600"
                            height="400"
                            backgroundColor="white"
                            onClickAway={() => this.closeModal()}
                          >
                            <div>
                              <p style={{ padding: "10px" }}>
                                {this.state.specification
                                  ? this.state.specification.description
                                  : "data not available"}
                              </p>

                              <a
                                style={{ marginLeft: "10px", color: "black" }}
                                href="javascript:void(0);"
                                onClick={() => this.closeModal()}
                              >
                                Close
                              </a>
                            </div>
                          </Modal>
                        </section> */}
        {/* //               </div> */}
        {/* //             ))
        //           : null}
        //       </div> */}
        {/* //     </div> */}
        {/* //   </section> */}
        {/* // </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          // animateOnce={false}
          animateOnce={true}
        >
          <div
            className="top-features-area wow fadeInUp"
            data-wow-delay="300ms"
            // style={{ marginTop: "100px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="features-content">
                    <div className="row no-gutters">
                      <div className="col-12 col-md-4">
                        <div className="single-top-features d-flex align-items-center justify-content-center">
                          <i className="icon-agenda-1"></i>
                          <h5>Online Courses</h5>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="single-top-features d-flex align-items-center justify-content-center">
                          <i className="icon-assistance"></i>
                          <h5>Amazing Teachers</h5>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="single-top-features d-flex align-items-center justify-content-center">
                          <i className="icon-telephone-3"></i>
                          <h5>Great Support</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          // animateOnce={false}
          animateOnce={true}
          isVisible={true}
        >
          <h3
            style={{
              textAlign: "center",
              marginTop: "50px",
              marginBottom: 0,
              fontWeight: 600,
              fontSize: "34px"
            }}
          >
            Upcoming Classes
          </h3>

          <div className="Academy-courses-area section-padding-100-0">
            <div className="container">
              <div className="row">
                {this.state.batches
                  ? this.state.batches.slice(0, 4).map((singleBatch, index) => (
                      <div className="col-12 col-sm-6 col-lg-6">
                        <div
                          className="single-course-area d-flex align-items-center mb-100 wow fadeInUp"
                          data-wow-delay="300ms"
                        >
                          <div className="course-icon">
                            <i className="icon-id-card"></i>
                          </div>
                          <div className="course-content">
                            <Link
                              to="/batches"
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              <h4>{singleBatch.name}</h4>
                            </Link>
                            <p>{singleBatch.description}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          // animateOnce={false}
          animateOnce={true}
        >
          <div
            className="testimonials-area section-padding-100 bg-img bg-overlay"
            // style="background-image: url(img/bg-img/bg-2.jpg);"
            style={{
              backgroundImage:
                "url(https://images.unsplash.com/photo-1531674842274-9563aa15686f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1402&q=80)"
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="section-heading text-center mx-auto white wow fadeInUp"
                    data-wow-delay="300ms"
                  >
                    <span>our testimonials</span>
                    <h3>
                      See what our satisfied customers are saying about us
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div
                    className="single-testimonial-area mb-100 d-flex wow fadeInUp"
                    data-wow-delay="400ms"
                  >
                    <div className="testimonial-thumb">
                      <img
                        src="https://lh5.googleusercontent.com/-nhnq2NAjCq8/AAAAAAAAAAI/AAAAAAAAAAA/-0FV8Krlwzk/s40-c-rp-mo-ba3-br100/photo.jpg"
                        width="100%"
                      />
                    </div>
                    <div className="testimonial-content">
                      <h5>Great teachers</h5>
                      <p>
                        I haven,t studied in Vpa But I am Thinking to join Vpa
                        cuz I,ve been suggested by my lot of Ca Friends to join
                        vpa.I hope that after joining Vpa I will be able to
                        acheive my dream.
                      </p>
                      <h6>
                        <span>Parth Kukde,</span> Student
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div
                    className="single-testimonial-area mb-100 d-flex wow fadeInUp"
                    data-wow-delay="500ms"
                  >
                    <div className="testimonial-thumb">
                      <img
                        src="https://lh5.googleusercontent.com/-nhnq2NAjCq8/AAAAAAAAAAI/AAAAAAAAAAA/-0FV8Krlwzk/s40-c-rp-mo-ba3-br100/photo.jpg"
                        width="100%"
                      />
                    </div>
                    <div className="testimonial-content">
                      <h5>Easy and user friendly courses</h5>
                      <p>
                        Best coaching facilities for commerce stream students...
                        Friendly staff and lovely atmosphere just acts as a
                        cherry on the cake.
                      </p>
                      <h6>
                        <span>Dipan Sur,</span> Student
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="load-more-btn text-center wow fadeInUp"
                    data-wow-delay="800ms"
                  >
                    <a
                      href="#"
                      className="btn Academy-btn"
                      // style={{ padding: "12px" }}
                    >
                      See More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={true}
        > */}
        <div className="call-to-action-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cta-content d-flex align-items-center justify-content-between flex-wrap" data-aos="fade-up">
                  <h3>Do you want to enroll at our Academy? Get in touch!</h3>
                  <Link to="/contact" className="btn Academy-btn download_btn">
                    Apply Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </ScrollAnimation> */}
        <Footer />
      </div>
    );
  }
}
