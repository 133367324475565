import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ScrollAnimation from "react-animate-on-scroll";
import Axios from "axios";
import Constants from "./Constants";
export default class Gallery extends Component {
  state = {
    gallery: [],
  };
  componentDidMount() {
    window.scrollTo(0,0);
    Axios.get(Constants.getUrls.gallery).then((res) => {
      console.log(res);
      this.setState({
        gallery: res.data,
      });
    });
  }

  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <div
          className="breadcumb-area bg-img"
          style={{
            backgroundImage:
              "url(https://upload.wikimedia.org/wikipedia/commons/7/77/Medlibrary.jpg)",
            backgroundPosition: "center",
          }}
        >
          <div className="bradcumbContent">
            <h2>Gallery </h2>
          </div>
        </div>

        <section className="ftco-section bg-light gallerySection">
          <div className="container">
            {/* <ScrollAnimation
              animateIn="fadeInUp"
              animationInDuration={1800}
              animateOnce={true}
              isVisible={true}
            > */}
              <div className="row">
                {this.state.gallery.map((singleImage, index) => (
                  <div className="col-md-6 col-lg-4 ">
                    <div style={{ marginBottom: 40 }}>
                      <div className="block-20 d-flex align-items-end">
                        <div className="gallery" data-aos="zoom-in">
                          <div className="some">
                            <img
                              src={Constants.imgUrl+singleImage.image}
                              width="100%"
                              className="image"
                              style={{ height: "260px" }}
                              alt={singleImage.name}
                            />
                            <div className="middle">
                              <div className="text">{singleImage.name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            {/* </ScrollAnimation> */}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
