import React, { Component } from "react";
import thanku from "../src/api/thankU.json";
import Lottie from "lottie-react-web";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer.jsx";
export default class ThankYou extends Component {
  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <Lottie
          options={{
            animationData: thanku
          }}
          style={{
            width: "200px",
            height: "200px"
          }}
        />
        <h4 style={{ textAlign: "center" }}>
          {" "}
          Your Request have been submitted successfully
        </h4>
        <a href="/">
          {" "}
          <h6
            style={{
              textAlign: "center",
              color: "black",
              marginBottom: "111px"
            }}
          >
            Go To Home{" "}
          </h6>
        </a>
        <Footer />
      </div>
    );
  }
}
