import React, { Component } from "react";
import Constants from "./Constants";
import Header from "./Header";
import Navbar from "./Navbar";

import Footer from "./Footer";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import Axios from "axios";

export default class Batches extends Component {
  state = {
    batches: [],
    specification: {},
    data: "",
  };
  componentDidMount() {
    window.scrollTo(0,0);
    // this.getbatches();
    let id = this.props.match.params.id;
    let data = this.props.location.state.name;

    console.log(this.props);
    Axios.get(Constants.getUrls.lablebycategory + id).then((res) => {
      console.log(res.data);
      this.setState({
        batches: res.data.batch,
        data,
      });
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      // alert("some");
      let id = this.props.match.params.id;
      let data = this.props.location.state.name;

      Axios.get(Constants.getUrls.lablebycategory + id).then((res) => {
        // console.log(res.data);
        this.setState({
          batches: res.data.batch,
          data,
        });
      });
    }
  }
  openModal(id) {
    // console.log(name);

    this.setState(
      {
        // visible : true,
        specification: this.state.batches.find((element) => element.id === id),
      },
      () => {
        console.log(this.state.specification);
      }
    );
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }
  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <div
          className="breadcumb-area bg-img"
          style={{
            backgroundImage:
              "url(https://upload.wikimedia.org/wikipedia/commons/7/77/Medlibrary.jpg)",
            backgroundPosition: "center",
          }}
        >
          <div className="bradcumbContent">
            <h2>Batches </h2>
          </div>
        </div>
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animateOnce={true}
          animationInDuration={1800}
          isVisible={true}
        > */}
          <section className="ftco-section">
            {/* <h5
              style={{
                textAlign: "center",
                marginBottom: "40px",
                fontWeight: "bold",
                fontSize: "25px"
              }}
            >
              {this.state.data ? this.state.data : null}
            </h5> */}

            <div className="container-fluid px-4">
              <div className="row">
                {/* <h3>{this.state.data.name} </h3> */}

                {this.state.batches.map((singleCourse) => (
                  <div data-aos="zoom-in"
                    className="col-md-3 course "
                    style={{ marginBbottom: "60px", height: "450px" }}
                  >
                    <div className="single-top-popular-course" style={{padding:"15px"}}>
                    <div className="img">
                      <img
                        alt={singleCourse.name}
                        src={Constants.imgUrl+singleCourse.image}
                        width="100%"
                      />
                    </div>
                    <div className="text pt-4">
                      <h3>{singleCourse.name}</h3>
                      <p>
                        {singleCourse.description
                          ? singleCourse.description
                          : "Description not available"}
                      </p>
                      <p>
                        {/* <b>Batch Tim :</b> {singleCourse.batch_time} */}
                      </p>
                      <section>
                        <Link
                          to={{
                            pathname: "/batchDetail",
                            state: {
                              name: singleCourse.name,
                              specification: singleCourse.specification,
                              batchtime: singleCourse.batch_time,
                              id: singleCourse.id,
                              file: singleCourse.file,
                            },
                          }}
                          className="btn download_btn"
                          onClick={() => this.openModal(singleCourse.id)}
                          style={{
                            padding: "12px",
                            fontWeight:"600",
                            color: "white",
                            margin:"0 40px"
                          }}
                        >
                          View / DownLoad
                        </Link>
                      </section>
                    </div>
                  </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        {/* </ScrollAnimation> */}

        <Footer />
      </div>
    );
  }
}
