// const mainDomain = "http://192.168.29.12:8000/";
// const mainDomain = "https://demotse.tk/";
const mainDomain = "https://core.vpaedu.com/"
// const mainDomain = "https://demotse.tk/";

const Constants = {
  imgUrl: mainDomain,
  getUrls: {
    homeImages: mainDomain + "api/home_image",
    results: mainDomain + "api/result",
    courses: mainDomain + "api/course",
    achievers: mainDomain + "api/achievers",
    gallery: mainDomain + "api/gallery",
    batches: mainDomain + "api/batches",
    teams: mainDomain + "api/our_team",
    labels: mainDomain + "api/label",
    popup: mainDomain + "api/poupmodel",
    lablebycategory: mainDomain + "api/label/show/",
    announce: mainDomain + "api/announcement",
    subjects: mainDomain + "api/allsubject?id=",
    batchwithsub: mainDomain + "api/batch/course/"
  },
  postUrls: {
    mail: mainDomain + "api/mail"
  },
  // key:'AIzaSyD8Nqc7Zq4RTr_lljDpeiY8V0_ToNoBg2o'
  key:'AIzaSyCGq2fLSjftzlU2kj8uZAIHA0wOzD994eA'
};
export default Constants;
