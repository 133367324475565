import React, { Component } from "react";
import Header from "./Header";
import Navbar from "./Navbar";

import Footer from "./Footer";
import Axios from "axios";
import Constants from "./Constants";
import ScrollAnimation from "react-animate-on-scroll";
import renderHTML from "react-render-html";

export default class CourseDetail extends Component {
  state = {
    courseDetail: []
  };
  componentDidMount() {
    console.log(this.props.location.state);
    Axios.get(Constants.getUrls.subjects + this.props.location.state.id).then(
      res => {
        console.log(res);
        this.setState(
          {
            courseDetail: res.data
          },
          () => {
            // console.log(this.state.courseDetail.id);
          }
        );
      }
    );
  }
  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          animateOnce={true}
          isVisible={true}
        >
          <div className="container">
            <h5
              style={{
                textAlign: "center",
                //   fontWeight: "bold",
                fontSize: "30px",
                marginTop: "80px"
              }}
            >
              Course Name:{this.props.location.state.name}
            </h5>
            {/* <h5> {this.props.location.state.specification} </h5> */}
            <div className="mainDiv">
              <div className="row">
                <div className="col-sm-6">
                  <table>
                    <tr>
                      <th>Sr No</th>
                      <th>Subjects</th>
                      <th>Faculty</th>
                    </tr>

                    {this.state.courseDetail.map((singlesub, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {singlesub.subject
                            ? singlesub.subject
                            : "subjects not available"}
                        </td>
                        <td>
                          {singlesub.our_team.name
                            ? singlesub.our_team.name
                            : "team not available"}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>

                <div className="col-sm-6">
                  <div className="wrapper">
                    <h4>Course Overview</h4>
                    <p>
                      {this.props.location.state.specification
                        ? renderHTML(this.props.location.state.specification)
                        : "Data not available"}
                      <div></div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <Footer />
      </div>
    );
  }
}
