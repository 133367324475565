import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollAnimation from "react-animate-on-scroll";
import Axios from "axios";
import Constants from "./Constants";
import Navbar from "./Navbar";
export default class Result extends Component {
  state = {
    results: [],
  };
  componentDidMount() {
    Axios.get(Constants.getUrls.results).then((res) => {
      console.log(res.data);
      this.setState({
        results: res.data,
      });
    });
  }

  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <div
          className="breadcumb-area bg-img"
          style={{
            backgroundImage:
              "url(https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80)",
            backgroundPosition: "center",
          }}
        >
          <div className="bradcumbContent">
            <h2>Results </h2>
          </div>
        </div>
        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          animateOnce={true}
          isVisible={true}
        >
          <section className="ftco-section">
            <div className="container-fluid px-4">
              <div className="row">
                {this.state.results.map((result, index) => (
                  <div className="col-md-3 course">
                    <div
                      className="img"
                      // style={{
                      //   backgroundImage: "url(" + result.file + ")"
                      // }}
                    >
                      <img
                        alt={result.course.name}
                        src={result.file}
                        width="100%"
                      />
                    </div>
                    <div className="text pt-4">
                      {/* <p className="meta d-flex"> {} </p> */}
                      <h3>{/* <a href="#">{}</a> */}</h3>
                      <p>{result.course.name}</p>
                      <p>{result.result_date} </p>
                      <p>
                        <button
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#fcd94b",
                            padding: "12px",
                            color: "white",
                            borderColor: "#fcd94b",
                            width: "30%",
                          }}
                        >
                          View
                        </button>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </ScrollAnimation>

        <Footer />
      </div>
    );
  }
}
