import React, { Component } from "react";
import Axios from "axios";
import Constants from "./Constants";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { GoPrimitiveDot } from "react-icons/go";
import { AiFillCaretRight } from "react-icons/ai";
import logo from "./assets/img/core-img/logo.png";

export default class About extends Component {
  state = {
    teams: [],
  };
  componentDidMount() {
    Axios.get(Constants.getUrls.teams).then((res) => {
      console.log(res);
      this.setState({
        teams: res.data,
      });
    });
  }
  render() {
    return (
      <div>
        {/* <Header /> */}
        {/* <Navbar /> */}
        {/* <div
          className="breadcumb-area bg-img"
          style={{
            backgroundImage:
              "url(https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80)",
            backgroundPosition: "center"
          }}
        >
          <div className="bradcumbContent">
            <h2>About Us</h2>
          </div>
        </div> */}
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <div
                className="section-heading text-center mx-auto wow"
                data-aos="fade-up"
              >
                {/* <span>The Best</span> */}
                <h3 style={{ fontSize: "34px", fontWeight: "900" }}>
                  Our Credentials
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12" data-aos="fade-right">
              <p
                className="announce_ment"
                style={{
                  textAlign: "justify",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <AiFillCaretRight style={{ color: "brown" }} />
                <span style={{ color: "red", fontWeight: "900" }}>
                  1504
                </span>{" "}
                Students have scored
                <span style={{ color: "red", fontWeight: "900" }}>
                  {" "}
                  90 & above
                </span>{" "}
                in XII (CBSE){" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  {" "}
                  Accounts
                </span>
                , from VPA.
              </p>
              <p
                className="announce_ment"
                style={{
                  textAlign: "justify",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <AiFillCaretRight style={{ color: "brown" }} />
                <span style={{ color: "red", fontWeight: "900" }}>
                  849
                </span>{" "}
                Students have scored
                <span style={{ color: "red", fontWeight: "900" }}>
                  {" "}
                  95 & above
                </span>{" "}
                in XII (CBSE)
                <span style={{ color: "red", fontWeight: "900" }}>
                  {" "}
                  Accounts
                </span>
                , from VPA.
              </p>
              <p
                className="announce_ment"
                style={{
                  textAlign: "justify",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <AiFillCaretRight style={{ color: "brown" }} />
                Std.XII (CBSE){" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  {" "}
                  Accounts Nagpur Topper
                </span>{" "}
                for last <br />{" "}
                <span style={{ color: "red", fontWeight: "900" }}>18</span>{" "}
                years are from VPA.
              </p>
            </div>
            <div className="col-md-6 col-12" data-aos="fade-left">
              <p
                className="announce_ment"
                style={{
                  textAlign: "justify",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <AiFillCaretRight style={{ color: "brown" }} />
                <span style={{ color: "red", fontWeight: "900" }}>
                  1993
                </span>{" "}
                Successful{" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  {" "}
                  Chartered Accountants
                </span>{" "}
                till date.
              </p>
              <p
                className="announce_ment"
                style={{
                  textAlign: "justify",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <AiFillCaretRight style={{ color: "brown" }} />
                <span style={{ color: "red", fontWeight: "900" }}>
                  137
                </span>{" "}
                Successful{" "}
                <span style={{ color: "red", fontWeight: "900" }}>
                  Company Secretaries{" "}
                </span>{" "}
                till date.
              </p>
              <p
                className="announce_ment"
                style={{
                  textAlign: "justify",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                <AiFillCaretRight style={{ color: "brown" }} />
                <span style={{ color: "red", fontWeight: "900" }}>
                  211 All India Merit
                </span>{" "}
                Students till date in{" "}
                <span style={{ color: "red", fontWeight: "900" }}>CA</span> &{" "}
                <span style={{ color: "red", fontWeight: "900" }}> CS </span>
                Examinations.
              </p>
            </div>
          </div>
        </div>

        {/* <section className="about-us-area mt-50 section-padding-100"> */}
        <div className="container">
          {/* <ScrollAnimation
            animateIn="fadeInUp"
            animationInDuration={1800}
            animateOnce={true}
            isVisible={true}
          > */}
          <div className="row">
            <div className="col-12">
              <div
                className="section-heading text-center mx-auto wow"
                data-aos="fade-up"
              >
                {/* <span>The Best</span> */}
                <h3>About Us</h3>
              </div>
            </div>
          </div>
          {/* </ScrollAnimation> */}
          {/* <ScrollAnimation
            animateIn="fadeInUp"
            animationInDuration={1800}
            animateOnce={true}
            isVisible={true}
          > */}
          {/* //cred */}

          <div className="row">
            <div className="col-12 col-md-6 wow" data-aos="fade-right">
              <CarouselProvider
                naturalSlideWidth={200}
                naturalSlideHeight={100}
                totalSlides={2}
                isPlaying={true}
                interval={3000}
              >
                <Slider>
                  <Slide index={0}>
                    <img
                      alt={"about"}
                      src="https://images.unsplash.com/photo-1567168544230-d5a9401299a4?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                      width="100%"
                    />
                  </Slide>
                  <Slide index={1}>
                    <img
                      alt={"about"}
                      src="https://images.unsplash.com/photo-1588618319407-948d4424befd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1945&q=80"
                      width="100%"
                    />
                  </Slide>
                </Slider>
              </CarouselProvider>
            </div>
            <div className="col-12 col-md-6 wow" data-aos="fade-left">
              <p style={{ textAlign: "justify", fontWeight: "600" }}>
                VPA, since 1996 has come long way with its quality education
                training & coaching in the field of Commerce ; Std XI-XII (CBSE/
                State) , in the field of Chartered Accountancy (CA) and Company
                Secretary(CS).
              </p>
              <p style={{ textAlign: "justify", fontWeight: "600" }}>
                VPA’s Motto is to impart quality education with wider horizon to
                students at large with affordable fee structure.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "600" }}>
                VPA’s single minded pursuit to excellence has lead its alumni
                picking up a number of All India Ranks at CA and CS Examination.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "600" }}>
                VPA is an institute that helps the student to develop an overall
                personality that helps them to face the competitive world
                fearlessly.
              </p>

              {/* <p style={{ textAlign: "justify", fontWeight: "600" }}>
              <GoPrimitiveDot />1803 Successful Chartered Accountants till date.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "600" }}>
              <GoPrimitiveDot /> 79 Successful Company Secretaries Till date.
              </p>
              <p style={{ textAlign: "justify", fontWeight: "600" }}>
              <GoPrimitiveDot />204 All India Merit Students till date in CA and CS
                Examinations.
              </p> */}
            </div>
          </div>

          {/* vpa crediencials */}

          {/* </ScrollAnimation> */}
          {/* <ScrollAnimation
            animateIn="fadeInUp"
            animationInDuration={1800}
            animateOnce={true}
            isVisible={true}
          > */}
          {/* <div className="row"> */}
          {/* <div className="col-12"> */}
          {/* <div
                className="about-slides owl-carousel mt-100 wow fadeInUp"
                data-wow-delay="600ms"
              > */}
          {/* <img src="img/bg-img/bg-3.jpg" alt="" />
                  <img src="img/bg-img/bg-2.jpg" alt="" />
                  <img src="img/bg-img/bg-1.jpg" alt="" /> */}
          {/* <CarouselProvider
                      naturalSlideWidth={200}
                      naturalSlideHeight={100}
                      totalSlides={2}
                      isPlaying={true}
                      interval={3000}
                    >
                      <Slider>
                        <Slide index={0}>
                          <img
                            src="https://images.unsplash.com/photo-1483446288147-2488bc359623?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=887&q=80"
                            width="100%"
                          />
                        </Slide>
                        <Slide index={1}>
                          <img
                            src="https://images.unsplash.com/photo-1506377872008-6645d9d29ef7?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
                            width="100%"
                          />
                        </Slide>
                      </Slider>
                    
                    </CarouselProvider> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </ScrollAnimation> */}
        </div>
        {/* </section> */}
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          animateOnce={true}
          isVisible={true}
        > */}
        {/* <section className="teachers-area section-padding-0-100">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="section-heading text-center mx-auto wow fadeInUp"
                    data-wow-delay="300ms"
                  >
                    <span>The Best</span>
                    <h3>Meet the Teachers</h3>
                  </div>
                </div>
              </div>

              <div className="row">
                {this.state.teams.slice(0, 4).map((singleTeam, index) => (
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div
                      className="single-teachers-area text-center mb-100 wow fadeInUp"
                      data-wow-delay="400ms"
                    >
                      <div className="teachers-thumbnail">
                        <img src={singleTeam.image} width="100%" />
                      </div>
                      <div className="teachers-info mt-30">
                        <h5>{singleTeam.name}</h5>
                        <span>{singleTeam.designation}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row">
                <div className="col-12">
                  <div
                    className="view-all text-center wow fadeInUp"
                    data-wow-delay="800ms"
                  >
                    <Link
                      to="/teacher"
                      className="btn Academy-btn"
                      // style={{ padding: "12px" }}
                    >
                      All Teachers
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        {/* </ScrollAnimation> */}
        {/* <Footer /> */}
      </div>
    );
  }
}
