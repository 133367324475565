import React, { Component } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ScrollAnimation from "react-animate-on-scroll";
import $ from "jquery";
import Axios from "axios";
import Constants from "./Constants";
import img2011 from "../src/assets/img/ac-img/cbse2011.jpg";
import img2012 from "../src/assets/img/ac-img/CBSE2012.jpg";
import img2013 from "../src/assets/img/ac-img/CBSE2013.jpg";
import img2014 from "../src/assets/img/ac-img/CBSE2014.jpg";
import img2015 from "../src/assets/img/ac-img/CBSE2015.jpg";
import img2016 from "../src/assets/img/ac-img/CBSE2016.jpg";
import img2017 from "../src/assets/img/ac-img/CBSE2017.jpg";
import img2018 from "../src/assets/img/ac-img/CBSE2018.jpg";
import img2019 from "../src/assets/img/ac-img/CBSE2019.jpg";
import img2021 from "../src/assets/img/ac-img/cbse2021.jpeg";
import sb2019 from "../src/assets/img/sb/SB2019.jpg";
import sb2018 from "../src/assets/img/sb/SB2018.jpg";
import sb2017 from "../src/assets/img/sb/SB2017.jpg";
import sb2021 from "../src/assets/img/sb/state2021.jpeg";
import img2020 from "../src/assets/img/ac-img/cbsc2020.jpg";
import sb2020 from "../src/assets/img/ac-img/stateboard2020.jpg";
import sb2022 from "../src/assets/img/ac-img/state2022.jpeg";
import img2022 from "../src/assets/img/ac-img/Cbse2022.jpeg";
import sb2023 from "../src/assets/img/ac-img/state2023.jpeg";
import img2023 from "../src/assets/img/ac-img/cbse2023.jpeg";
import sb2024 from "../src/assets/img/ac-img/state2024.jpeg";
import img2024 from "../src/assets/img/ac-img/cbse2024.jpeg";

export default class Achievers extends Component {
  state = {
    achievers: [],
    data: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    let data = this.props.location.state;
    let id = this.props.match.params.id;

    Axios.get(Constants.getUrls.lablebycategory + id).then((res) => {
      console.log(res.data);
      this.setState(
        {
          achievers: res.data.achivers,
          data,
        },
        () => {
          console.log(this.state.achievers);
        }
      );
    });

    var loader = function() {
      setTimeout(function() {
        if ($("#ftco-loader").length > 0) {
          $("#ftco-loader").removeClass("show");
        }
      }, 1);
    };
    loader();
  }
  componentDidUpdate(prevProps) {
    // alert("some");
    if (prevProps.match.params.id !== this.props.match.params.id) {
      let id = this.props.match.params.id;
      Axios.get(Constants.getUrls.lablebycategory + id).then((res) => {
        // console.log(res.data);
        this.setState(
          {
            achievers: res.data.achivers,
            data: this.props.location.state,
            // description: res.data.course[0].specification
          },
          () => {
            // console.log(this.state.courses);
          }
        );
      });
    }
  }
  render() {
    return (
      <div>
        <Header />
        <Navbar />
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeOut"
          animationInDuration={1800}
          animationOutDuration={1800}
          isVisible={true}
        > */}
        <div
          className="breadcumb-area bg-img"
          // style="background-image: url(img/bg-img/breadcumb.jpg);"
          style={{
            backgroundImage:
              "url(https://upload.wikimedia.org/wikipedia/commons/7/77/Medlibrary.jpg)",
            backgroundPosition: "center",
          }}
        >
          <div className="bradcumbContent">
            <h2>Top Achievers </h2>
          </div>
        </div>
        {/* </ScrollAnimation> */}
        <section
          className="ftco-section bg-light achieversSetion"
          data-aos="fade-up"
        >
          <h3
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: "bold",
            }}
          >
            Our{" "}
            {this.state.data.name === "XI-XII CBSE" ||
            this.state.data.name === "XI-XII State Board"
              ? "Achievers"
              : " Recent All India Ranks"}{" "}
            In {this.state.data.name}
          </h3>
          <div className="container-fluid px-4">
            {/* <ScrollAnimation
              animateIn="fadeInUp"
              animationInDuration={1800}
              animateOnce={true}
              isVisible={true}
            > */}
            <div className="row">
              {this.state.data.name === "XI-XII CBSE" ? (
                <div className="col-md-12">
                  <h3
                    style={{
                      textAlign: "center ",
                      fontWeight: "bold",
                      // paddingTop: "15px",
                      paddingBottom: "15px",
                      // marginRight:"600px",
                      // marginLeft:"600px",
                      // fontWeight:"bold",
                      marginTop: "-15px",
                    }}
                  >
                    (2024 to 2011)
                  </h3>
                  <img
                    src={img2024}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2023}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2022}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2021}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2020}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />

                  <img
                    src={img2019}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />

                  <img
                    src={img2018}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2017}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2016}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2015}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2014}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2013}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2012}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={img2011}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                </div>
              ) : this.state.data.name === "XI-XII State Board" ? (
                <div className="col-md-12">
                  <h3
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      // paddingTop: "15px",
                      paddingBottom: "15px",
                      // paddingRight:"600px",
                      // paddingLeft:"600px",
                      // fontWeight:"bold",
                      marginTop: "-15px",
                    }}
                  >
                    (2024 to 2017)
                  </h3>
                  <img
                    src={sb2024}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={sb2023}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={sb2022}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={sb2021}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={sb2020}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={sb2019}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={sb2018}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                  <img
                    src={sb2017}
                    alt="some"
                    style={{ paddingTop: "15px", width: "100%" }}
                  />
                </div>
              ) : (
                this.state.achievers.map((person, index) => (
                  <div className="col-md-6 col-lg-2" data-aos="fade-up">
                    <div className="staff">
                      <div className="img-wrap d-flex align-items-stretch">
                        <div
                          className="img align-self-stretch img_focus"
                          // style={{
                          //   backgroundImage: "url(" + person.image + ")"
                          // }}
                        >
                          <img
                            src={Constants.imgUrl + person.image}
                            width="100%"
                            alt={person.name}
                            // style={{ height: "375px" }}
                          />
                        </div>
                      </div>
                      <div className="text pt-3 text-center">
                        <div className="faded nameCenter">
                          <ul className="ftco-social text-center">
                            <h5>{person.name} </h5>
                            {/* <h5>{person.course.name} </h5> */}
                            <p tyle={{ marginTop: "" }}> {person.rank}</p>
                            <p style={{ marginTop: "" }}>
                              {person.description}
                            </p>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            {/* </ScrollAnimation> */}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
