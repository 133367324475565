import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Axios from "axios";
// import Constants from "./Constants";
import Constants from "./Constants";
import Navbar from "./Navbar";
import ScrollAnimation from "react-animate-on-scroll";

export default class UpcomingDetail extends Component {
  state = {
    courseDetail: [],
    subjects: [],
  };
  componentDidMount() {
    window.scrollTo(0,0);
    // alert(Constants.imgUrl);
    Axios.get(
      Constants.getUrls.batchwithsub + this.props.location.state.id
    ).then((resp) => {
      console.log(resp)
      this.setState({
        subjects: resp.data.course ? resp.data.course.subject : [],
      });
    });
    // Axios.get(Constants.getUrls.subjects + this.props.location.state.id).then(
    //   res => {
    //     console.log(res);
    //     this.setState(
    //       {
    //         courseDetail: res.data
    //       },
    //       () => {
    //         // console.log(this.state.courseDetail.id);
    //       }
    //     );
    //   }
    // );
  }

  render() {
    return (
      <div>
        <Header />
        <Navbar />
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={true}
        > */}
          <div
            className="mainContainer"
            style={{ position: "relative", fontSize: "15px" }}
          >
            <div className="tableCenter" data-aos="fade-up">
              <p className="upName">{this.props.location.state.name} </p>
              <p
                className=""
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                  marginTop: "-30px",
                  textAlign: "center",
                }}
              >
                {" "}
                {this.props.location.state.batchtime}{" "}
              </p>

              <p className="upName">{this.props.location.state.date} </p>
              <p className="upName">{this.props.location.state.time} </p>

              <table>
                <tr>
                  <th>Sr no</th>
                  <th>Subjects</th>
                  
                  <th>Faculty</th>
                </tr>

                {this.state.subjects.map((singlesub, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {singlesub.subject
                        ? singlesub.subject
                        : "subjects not available"}
                    </td>
                    <td>{singlesub.our_team ? (singlesub.our_team.name) : "Faculty Not Available"}</td>
                    
                  </tr>
                ))}
              </table>
              <a
                href={
                  "https://core.vpaedu.com/api/batch/download?file=" +
                  this.props.location.state.file
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn download_btn"
                  style={{
                    marginTop: "20px",
                    position: "absolute",
                    left: "67%",
                    color: "white",
                    border: "none",
                    padding: "12px",
                  }}
                  onClick={() => {
                    Axios.get(
                      `https://core.vpaedu.com/api/batch/download?file=` +
                      this.props.location.state.file
                    ).then((res) => {
                      console.log(res);
                    });
                  }}
                >
                  Download
                </button>
              </a>
            </div>
          </div>
        {/* </ScrollAnimation> */}
        <Footer />
      </div>
    );
  }
}
