import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Constants from "./Constants";
import logo from "./assets/img/core-img/logo.png";

export default class Footer extends Component {
  state = {
    gallery: [],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    Axios.get(Constants.getUrls.gallery).then((res) => {
      console.log(res);
      this.setState({
        gallery: res.data,
      });
    });
  }

  render() {
    return (
      <div>
        <footer class="footer-area">
          <div class="main-footer-area section-padding-100-0">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-3">
                  <div class="footer-widget mb-100">
                    <div class="widget-title">
                      {/* <a href="#"> */}
                      <img alt={"logo"} src={logo} width="100px" />
                      {/* </a> */}
                    </div>
                    {/* <p>
                      Cras vitae turpis lacinia, lacinia lacus non, fermentum
                      nisi. Donec et sollicitudin est, in euismod erat. Ut at
                      erat et arcu pulvinar cursus a eget.
                    </p> */}
                    <p style={{ color: "white" }}>Branches</p>
                    <div class="single-contact d-flex mb-30">
                      <span>
                        <i class="icon-placeholder"></i>
                      </span>
                      <span style={{ color: "gainsboro" }}>Branch 1</span>
                    </div>
                    <p
                      style={{
                        marginLeft: "30px",
                        marginTop: "-30px",
                        color: "gainsboro",
                      }}
                    >
                      Dharampeth, Nagpur
                    </p>
                    <div class="single-contact d-flex mb-30">
                      <span>
                        <i class="icon-placeholder"></i>
                      </span>
                      <span style={{ color: "gainsboro" }}>Branch 2</span>
                    </div>
                    <p
                      style={{
                        marginLeft: "30px",
                        marginTop: "-30px",
                        color: "gainsboro",
                      }}
                    >
                      Ramdaspeth, Nagpur
                    </p>

                    {/* <div class="single-contact d-flex mb-30">
                      <span>
                        <i class="icon-placeholder"></i>
                        <span style={{ color: "gainsboro" }}>Branch 3</span>
                      </span>
                    </div>
                    <p style={{ marginLeft: "30px", marginTop: "-30px", color: "gainsboro" }}>
                      Sadar, Nagpur.
                    </p> */}

                    <div
                      class="footer-social-info"
                      style={{ marginLeft: "30px" }}
                    >
                      <a href="https://www.facebook.com/cavpa/" target="_blank">
                        <i class="fa fa-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/vpa_finest_commerce_academy/"
                        target="_blank"
                      >
                        <i class="fa fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UC1OGmRkbBP78SDWi5HYhITw"
                        target="_blank"
                      >
                        <i class="fa fa-youtube"></i>
                      </a>
                      {/* <a href="#">
                        <i class="fa fa-behance"></i>
                      </a>
                      <a href="#">
                        <i class="fa fa-instagram"></i>
                      </a> */}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                  <div class="footer-widget mb-100">
                    <div class="widget-title">
                      <h6>Important Links</h6>
                    </div>
                    <nav>
                      <ul class="useful-links">
                        <li>
                          <a href="https://www.icai.org/" target="_blank">
                            www.icai.org
                          </a>
                        </li>
                        <li>
                          <a href="https://www.icsi.edu/home/" target="_blank">
                            www.icsi.edu
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a href="http://www.cbse.nic.in/" target="_blank">
                            www.cbse.nic.in
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            href="https://www.mahahsscboard.in/"
                            target="_blank"
                          >
                            www.mahahsscboard.in
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            href="https://vpaedu.com/privacypolicy.html"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        {/* <Link
                          to="www.icai.org"
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "gainsboro",
                            fontSize: "15px",
                          }}
                        >
                          www.icai.org
                        </Link> */}
                        {/* <Link
                          to="www.icsi.edu"
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "gainsboro",
                            fontSize: "15px",
                          }}
                        >
                          www.icsi.edu
                        </Link> */}
                        {/* <Link
                          to="www.cbse.nic.in"
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "gainsboro",
                            fontSize: "15px",
                          }}
                        >
                          www.cbse.nic.in
                        </Link> */}
                        {/* <Link
                          to="https://www.mahahsscboard.maharashtra.gov.in/"
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "gainsboro",
                            fontSize: "15px",
                          }}
                        >
                          www.mahahsscboard.<br></br>maharashtra.gov.in
                        </Link> */}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                  <div class="footer-widget mb-100">
                    <div class="widget-title">
                      <h6>Gallery</h6>
                    </div>
                    <div class="gallery-list d-flex justify-content-between flex-wrap">
                      {this.state.gallery
                        .slice(0, 6)
                        .map((singleImage, index) => (
                          <a
                            target="_blank"
                            href={Constants.imgUrl + singleImage.image}
                            class="gallery-img"
                            title="Gallery Image 1"
                          >
                            <img
                              alt={"slider"}
                              src={Constants.imgUrl + singleImage.image}
                              width="100%"
                            />
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                  <div class="footer-widget mb-100">
                    <div class="widget-title">
                      <h6>Contact</h6>
                    </div>
                    <div class="single-contact d-flex mb-30">
                      <i class="icon-placeholder"></i>
                      <p style={{ color: "gainsboro" }}>
                        Inside the Premises of Dharampeth Public School, Beside
                        Kusumtai Wankhede Sabhagruh, North Ambazari Road,
                        Dharampeth, Nagpur-10.
                      </p>
                    </div>
                    <div
                      class="single-contact  mb-30"
                      style={{ display: "block" }}
                    >
                      <i class="icon-telephone-1" />
                      {/* <a
                        href="tel:7447799773"
                        style={{ color: "gainsboro", fontWeight: "400" }}
                      >
                        Main: 7447799773
                      </a> */}
                      <a
                        href="tel:7447799773"
                        onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '7447799773', 0);"
                        style={{ fontWeight: "400" }}
                        className="color_gains"
                      >
                        CALL 7447799773
                      </a>
                      <br />
                      {/* <a
                        href="tel:9420251647"
                        style={{
                          color: "gainsboro",
                          fontWeight: "400",
                          marginLeft: "27px"
                        }}
                      >
                        Office: 9420251647
                      </a>{" "} */}
                      <a
                        href="tel: 9420251647"
                        onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', ' 9420251647', 0);"
                        style={{
                          fontWeight: "400",
                          marginLeft: "36px",
                        }}
                        className="color_gains"
                      >
                        CALL 9420251647
                      </a>
                      {/* <p>
                        Main: 7447799773 <br />
                        Office: 9420251647
                      </p> */}
                    </div>
                    <div class="single-contact d-flex">
                      <i class="icon-contract"></i>
                      <a
                        href="mailto:vpangpinfo@gmail.com"
                        style={{ fontWeight: "400" }}
                        className="color_gains"
                      >
                        {" "}
                        vpangpinfo@gmail.com{" "}
                      </a>
                      {/* <i class="icon-contract"></i> */}
                      {/* <p>vpangpinfo@gmail.com</p> */}
                    </div>
                    <div class="counter">
                      <p
                        style={{
                          color: "#d4d4d4",
                          marginTop: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Visitor's Count :
                      </p>
                      <a
                        href="https://www.freecounterstat.com"
                        title="web page visit counter"
                      >
                        <img
                          src="https://counter5.optistats.ovh/private/freecounterstat.php?c=lneb56fa5nxmyhumnjlc8qgu24xmura2"
                          border="0"
                          title="web page visit counter"
                          alt="web page visit counter"
                        ></img>
                      </a>
                      {/* <a href="https://www.webfreecounter.com/" target="_blank"><img src="https://www.webfreecounter.com/hit.php?id=geuoodcp&nd=6&style=48" border="0" alt="visitor counter"></img>
                      </a> */}
                      {/* <a href="" target="_blank"></a> */}
                      {/* <a href="/" target="_blank">
<img src="https://hitwebcounter.com/counter/counter.php?page=7765533&style=0001&nbdigits=5&type=ip&initCount=00001" title="Free Counter" Alt="web counter"   border="0" /></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* hitwebcounter Code START */}

          {/* <script type="text/javascript" src="https://free-hit-counters.net/count/8cll"></script>
          
<a href='https://healthnewsnet.de/'>HealthNews</a>
 <script type='text/javascript' src='https://www.whomania.com/ctr?id=eb3d9a60999ce269a3fa0a26680c40e3358094a5'></script> */}

          <div class="bottom-footer-area">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>
                    Copyright &copy;
                    <script>
                      document.write(new Date().getFullYear());
                    </script>{" "}
                    All rights reserved | Made with{" "}
                    <i
                      class="fa fa-heart-o"
                      aria-hidden="true"
                      style={{
                        color: "Red",
                      }}
                    ></i>{" "}
                    by{" "}
                    <a
                      href="https://thespaceelement.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span
                        style={{
                          color: "#fff",
                        }}
                      >
                        {" "}
                        The{" "}
                        <span
                          style={{
                            color: "Red",
                          }}
                        >
                          {" "}
                          Space
                        </span>{" "}
                        Element
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
