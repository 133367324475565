import React, { Component } from "react";
import Footer from "./Footer";
import $ from "jquery";
import Header from "./Header";
import ScrollAnimation from "react-animate-on-scroll";
import Axios from "axios";
import Constants from "./Constants";
import Navbar from "./Navbar";
import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";

export default class Teacher extends Component {
  state = {
    teams: [],
    specification: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0,0);
    Axios.get(Constants.getUrls.teams).then((res) => {
      console.log(res);
      this.setState({
        teams: res.data,
      });
    });
    var loader = function() {
      setTimeout(function() {
        if ($("#ftco-loader").length > 0) {
          $("#ftco-loader").removeClass("show");
        }
      }, 1);
    };
    loader();
  }
  openModal(id) {
    this.setState(
      {
        visible: true,
        specification: this.state.teams.find((element) => element.id === id),
      },
      () => {
        console.log(this.state.specification);
      }
    );
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <div>
        <Header />
        <Navbar />
        <div
          className="breadcumb-area bg-img"
          style={{
            backgroundImage:
              "url(https://upload.wikimedia.org/wikipedia/commons/7/77/Medlibrary.jpg)",
            backgroundPosition: "center",
          }}
        >
          <div className="bradcumbContent">
            <h2>MEET OUR TEAM</h2>
          </div>
        </div>
        <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={true}
        >
          {/* <div
            style={{
              
              textAlign: "center",
              padding: "0px",
              marginTop: "100px",
              marginBottom: "50px"
            }}
          >
            <h4 
            style={{fontSize:"30px",
              fontWeight:"bold"}}></h4>
            {/* <h1>BEST TEAM</h1> */}
          {/* </div> */}
        </ScrollAnimation>
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          isVisible={true}
          animateOnce={true}
        > */}
          <section className="ftco-section bg-light">
            <div className="container-fluid px-4">
              <div className="row">
                {this.state.teams
                  ? this.state.teams.map((singleTeam, index) => (
                      // <div>
                      <div
                        className={
                          index === 0
                            ? "col-12 col-sm-6 col-lg-3 offset-lg-3 "
                            : index === 1
                            ? "col-12 col-sm-6 col-lg-3 marginRight"
                            : "col-12 col-sm-6 col-lg-3 "
                        }
                      >
                        <div
                          className="single-teachers-area text-center mb-100 wow" data-aos="fade-up"
                          // data-wow-delay="400ms"
                        >
                          <div className="img-hover-zoom img-hover-zoom--xyz shadow" data-aos="zoom-in">
                            <img
                              src={Constants.imgUrl+singleTeam.image}
                              width="100%"
                              // height="350px"
                              style={{ height: "350px" }}
                              alt={singleTeam.name} className="teacher_img teachers-thumbnail"
                            />
                          </div>
                          <div className="teachers-info mt-30">
                            <h5>{singleTeam.name}</h5>
                            {/* <Link
                              className="btn btn-primary"
                              onClick={() => this.openModal(singleTeam.id)}
                            >
                              Know More
                            </Link> */}
                            {/* <span>{singleTeam.designation}</span> */}
                          </div>
                        </div>
                        <section className="text-center">
                          <input
                            type="button"
                            value="Know More"
                            className="btn Academy-btn btn-sm know_more"
                            onClick={() => this.openModal(singleTeam.id)}
                          />
                        </section>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </section>
        {/* </ScrollAnimation> */}
        <Modal
          visible={this.state.visible}
          width="800"
          // height="400"
          // swipeDirection={"left"}
          // effect="fadeInUp"
          data-aos="fade-up"
          backgroundColor="lightgray"
          onClickAway={() => this.closeModal()}
        >
          <div style={{ padding: "15px" }}>
            <h4>
              {this.state.specification ? this.state.specification.name : null}
            </h4>
            <p>
              {/* renderHTML (  ) */}
              {renderHTML(
                this.state.specification
                  ? this.state.specification.description
                  : "data not available"
              )}
            </p>
            <input
              type="button"
              value="Close"
              className="btn Academy-btn btn-sm modal_btn"
              style={
                {
                  // marginTop: "-155px",
                  // marginLeft: "110px"
                }
              }
              // href="javascript:void(0);"
              onClick={() => this.closeModal()}
              // onClick={() => this.openModal(singleTeam.id)}
            />
            {/* <a
              style={{ marginLeft: "10px", color: "black", backgroundColor:"#fcda4b", padding:"10px 10px" }}
              href="javascript:void(0);"
              onClick={() => this.closeModal()}
            >
              Close
            </a> */}
          </div>
        </Modal>
        <Footer />
      </div>
    );
  }
}
