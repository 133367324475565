import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./assets/css/style.css";
import whatsapp from "./assets/img/whatsapp.png";
// import "./assets/img/logo.png";
import "../src/assets/css/animate.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/classy-nav.min copy.css";
import "../src/assets/css/classy-nav.min.css";
import "../src/assets/css/custom-icon.css";
import "../src/assets/css/magnific-popup.css";
import Home from "./Home";
import About from "./About";
import Courses from "./Courses";
import Teacher from "./Teacher";
import Gallery from "./Gallery";
import Result from "./Result";
import Achievers from "./Achievers";
import UpcomingBatchesDetail from "./UpcomingBatchesDetail";
import CourseDeatail from "./CourseDeatail";
import Batches from "./Batches";
import Contact from "./Contact";
import Announcement from "./Announcement";
import ThankYou from "./ThankYou";
import FreshChat from "react-freshchat";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const freshchatToken = "84bea87b-d88f-4f08-a61c-bcf5c08115e3";
const screenWidth = window.screen.width;
export default class App extends Component {
  render() {
    return (
      <div>

        <BrowserRouter>

          <a
            href="https://api.whatsapp.com/send?phone=+917447799773&text=Hello VPA, I have Query.."
            target="_blank"
            rel="noreferrer">
            <img src={whatsapp} alt="" className="whtsapp" />
          </a>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/course/:id" component={Courses} />
          <Route path="/teacher" component={Teacher} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/result" component={Result} />
          <Route path="/achievers/:id" component={Achievers} />
          <Route path="/batchDetail" component={UpcomingBatchesDetail} />
          <Route path="/courseDetail" component={CourseDeatail} />
          <Route path="/batches/:id" component={Batches} />
          <Route path="/contact" component={Contact} />
          <Route path="/announce" component={Announcement} />
          <Route path="/thank-you" component={ThankYou} />
        </BrowserRouter>



      </div>
    );
  }
}
