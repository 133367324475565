import React, { Component } from "react";
import Axios from "axios";
import Constants from "./Constants";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { GoArrowRight, GoBell, GoBook, GoLightBulb } from "react-icons/go";

export default class Announcement extends Component {
  state = {
    announce: [],
  };
  componentDidMount = () => {
    Axios.get(Constants.getUrls.announce).then((res) => {
      console.log(res.data);
      this.setState({
        announce: res.data,
      });
    });
  };

  render() {
    return (
      <div>
        <Navbar />
        <div
          className="breadcumb-area bg-img"
          style={{
            backgroundImage:
              "url(https://upload.wikimedia.org/wikipedia/commons/7/77/Medlibrary.jpg)",
            backgroundPosition: "center",
          }}
        >
          <div className="bradcumbContent">
            <h2>Announcements</h2>
          </div>
        </div>
        <div
          className="container"
          style={{ marginTop: "90px", marginBottom: "90px" }}
        >
          {this.state.announce.map((single, index) => (
            <div
              style={{
                // textAlign: index % 2 === 0
                // ? "left"
                // : "right",
                textAlign: "left",
                marginTop: "30px",
              }}
              data-aos="fade-up"
            >
              {/* <h1>Announcements</h1> */}
              <h5 className="announce_ment">
                <GoLightBulb />
                {single.announcement}{" "}
              </h5>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}
