import React, { Component } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import { Redirect } from "react-router-dom";
import Footer from "./Footer";
import ScrollAnimation from "react-animate-on-scroll";
import GoogleMapReact from "google-map-react";
import Axios from "axios";
import loader from "../src/api/loader.json";
import Lottie from "lottie-react-web";
import Constants from "./Constants";
import logo from "./assets/img/core-img/logo.png";
import pin from "./assets/img/pin.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      fontSize: "20px",
      fontWeight: "bold",
      width: "300px",
      textAlign: "center"
    }}
  >
    {" "}
    <img src={pin} alt={"pin"} width="20px" /> {text}
  </div>
);

export default class Contact extends Component {
  state = {
    courses: [],
    first_name: "",
    last_name: "",
    email: "",
    course: "",
    mobile: "",
    messages: "",
    btnLoading: false
  };
  componentDidMount() {
    window.scrollTo(0,0);
    Axios.get(Constants.getUrls.courses).then(res => {
      console.log(res);
      this.setState({
        courses: res.data
      });
    });
  }
  onNumberChange = (e) => {
    if(e.target.value.length <=10){
    this.setState({mobile: e.target.value})
    }
    }
  onSubmit = (e) => {
    e.preventDefault()
    this.setState({
      btnLoading: true
    });
    if(this.state.mobile.length === 10){
    const payLoad = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      course: this.state.course,
      mobile: this.state.mobile,
      messages: this.state.messages,
      email: this.state.email
    };
    console.log(payLoad);
    Axios.post(Constants.postUrls.mail, payLoad).then(res => {
      console.log(res);
      // alert("Request have been submitted successfully");
      this.setState({
        first_name: "",
        last_name: "",
        email: "",
        course: "",
        mobile: "",
        messages: "",
        redirect: true
      });
    });
  }
  else{
    this.setState({btnLoading:false})
    alert('Invalid Mobile Number!! Please Enter your 10 Digit Mobile Number')
  }
  };
  handleChange = e => {
    // console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  static defaultProps = {
    center: {
      lat: 21.136991,
      lng: 79.066318
    },
    zoom: 14
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/thank-you"
            // state: this.state.success
          }}
        />
      );
    }
    return (
      <div>
        <Header />
        <Navbar />
        <div
          className="breadcumb-area bg-img"
          // style="background-image: url(img/bg-img/breadcumb.jpg);"
          style={{
            backgroundImage:
              "url(https://upload.wikimedia.org/wikipedia/commons/7/77/Medlibrary.jpg)",
            backgroundPosition: "center"
          }}
        >
          <div className="bradcumbContent">
            <h2>Contact</h2>
          </div>
        </div>
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOnce={true}
          animationInDuration={1800}
          isVisible={true}
        >
          
        </ScrollAnimation>
        {/* <ScrollAnimation
          animateIn="fadeInUp"
          animationInDuration={1800}
          animateOnce={true}
          isVisible={true}
        > */}
          <section className="contact-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="contact-content">
                    <div className="row">
                      <div className="col-12 col-lg-5">
                        <div
                          className="contact-information wow"
                          data-aos="fade-up"
                        >
                          <div className="section-heading text-left">
                            {/* <span>The Best</span> */}
                            <h3
                              style={{
                                marginTop: "-80px",
                                marginBottom: "20px"
                              }}
                            >
                              Contact Us
                            </h3>
                            {/* <p className="mt-30">
                              Best Commerce Coaching Academy For Undergraduates
                              Coaching In Central India.First Commerce Coaching
                              Academy In Nagpur.Best Academy for CA Coaching In
                              Central India.
                            </p> */}
                            {/* <a href="#"> */}
                            <img alt={"logo"} src={logo} width="100px" />
                            {/* </a> */}
                          </div>

                          <div className="contact-social-info d-flex mb-30">
                            {/* <a href="#">
                              <i
                                className="fa fa-pinterest"
                                aria-hidden="true"
                              ></i>
                            </a> */}
                            <a href="https://www.facebook.com/cavpa/" target="_blank">
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a href="https://www.instagram.com/vpa_finest_commerce_academy/" target="_blank">
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UC1OGmRkbBP78SDWi5HYhITw" target="_blank">
                              <i
                                className="fa fa-youtube"
                                aria-hidden="true"
                              ></i>
                            </a>
                            {/* <a href="#">
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </a> */}
                            {/* <a href="#">
                              <i
                                className="fa fa-dribbble"
                                aria-hidden="true"
                              ></i>
                            </a> */}
                            {/* <a href="#">
                              <i
                                className="fa fa-behance"
                                aria-hidden="true"
                              ></i>
                            </a> */}
                            {/* <a href="#">
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a> */}
                          </div>

                          <div className="single-contact-info d-flex">
                            <div className="contact-icon mr-15">
                              <i className="icon-placeholder"></i>
                            </div>
                            <p>
                              Inside the Premises of Dharampeth Public School, Beside Kusumtai Wankhede Sabhagruh, North Ambazari Road, Dharampeth, Nagpur-10.
                              {/* <br /> Nagpur-10 */}
                            </p>
                          </div>

                          <div className="single-contact-info d-flex">
                            <div className="contact-icon mr-15">
                              <i className="icon-telephone-1"></i>
                            </div>
                            <p>
                              {" "}
                              {/* <a
                                href="tel:7447799773"
                                style={{ color: "#000", fontWeight: "400" }}
                              >
                                Main: 7447799773{" "}
                              </a>{" "} */}
                              <a
                                href="tel:7447799773"
                                onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '7447799773', 0);"
                                // style={{ fontWeight: "700" }}
                                style={{ color: "#000", fontWeight: "400" }}
                              >
                                {/* <i className="icon-telephone-2"></i> */}
                                {/* <span>(+91) 7447799773</span> */}
                                CALL 7447799773
                              </a>
                              <br />
                              {/* <a
                                href="tel:9420251647"
                                style={{ color: "#000", fontWeight: "400" }}
                              >
                                {" "}
                                Office: 9420251647
                              </a>{" "} */}
                              <a
                                href="tel: 9420251647"
                                onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', ' 9420251647', 0);"
                                style={{ color: "#000", fontWeight: "400" }}
                              >
                                CALL 9420251647
                              </a>
                            </p>
                          </div>

                          <div className="single-contact-info d-flex">
                            <div className="contact-icon mr-15">
                              <i className="icon-contract"></i>
                            </div>
                            <p>
                              {" "}
                              <a
                                href="mailto:vpangpinfo@gmail.com"
                                style={{ color: "#000", fontWeight: "400" }}
                              >
                                {" "}
                                vpangpinfo@gmail.com{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-7">
                        <div
                          className="contact-form-area wow"
                          data-aos="fade-up"
                        >
                          <form onSubmit={(e)=>{this.onSubmit(e)}}>
                          <input
                           required
                            type="text"
                            name="first_name"
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            className="form-control"
                            placeholder="First Name"
                            value={this.state.first_name}
                          />
                          <input
                            type="text"
                            name="last_name"
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            className="form-control"
                            placeholder="Last Name"
                            value={this.state.last_name}
                          />
                          <input
                          required
                            type="email"
                            name="email"
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            className="form-control"
                            placeholder="Email"
                            value={this.state.email}
                          />
                          {/* <select
                            name="course"
                            className="form-control"
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            style={{ height: "50px" }}
                          >
                            {this.state.courses.map(single => (
                              <option value={single.name}>{single.name}</option>
                            ))}
                          </select> */}
                          <select
                            name="course"
                            className="form-control"
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            style={{ height: "50px" }}
                          >
                            <option value="CA Foundation">
                              CA Foundation{" "}
                            </option>
                            <option value="CA Intermediate ">
                              CA Intermediate{" "}
                            </option>
                            <option value="CA Final">CA Final </option>
                            <option value="CS EET / CS Foundation">
                              CS EET / CS Foundation{" "}
                            </option>
                            <option value="CS Executive">CS Executive </option>
                            <option value="CS Professional">
                              CS Professional{" "}
                            </option>
                            <option value="Std. XI – XII CBSE">
                              Std. XI – XII CBSE{" "}
                            </option>
                            <option value="Std. XI – XII State Board">
                              Std. XI – XII State Board{" "}
                            </option>
                          </select>
                          {/* <input type="text" pattern="\d*" maxlength="4"></input> */}
                          {/* <input type="number" onKeyPress="if(this.value.length==10) return false;" /> */}
                          <input
                          required
                         
                            type="number"
                            name="mobile"
                            // onKeyPress="if(this.value.length==10) return false;"
                            onChange={e => {
                            this.onNumberChange(e)
                            }}
                            className="form-control"
                            placeholder="Phone"
                            value={this.state.mobile}
                          />
                          <textarea
                            id=""
                            cols="30"
                            rows="7"
                            name="messages"
                            onChange={e => {
                              this.handleChange(e);
                            }}
                            className="form-control"
                            placeholder="Message"
                            value={this.state.messages}
                          ></textarea>

                          <button
                            className="btn Academy-btn mt-30"
                            // onClick={() => {
                            //   this.onSubmit();
                            // }}
                          >
                            {this.state.btnLoading ? (
                              <Lottie
                                options={{
                                  animationData: loader
                                }}
                                style={{
                                  width: "20px",
                                  height: "20px"
                                }}
                              />
                            ) : (
                                "Submit"
                              )}
                          </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            className="map-area wow"
            data-wow-delay="300ms"
            style={{ height: "600px", width: "100%", marginTop:"10px",marginBottom:"10px" }}
          >
            {/* <div id="googleMap"></div> */}
            <GoogleMapReact
              bootstrapURLKeys={{ key: Constants.key, language: "en" }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <AnyReactComponent
                lat={21.136991}
                lng={79.066318}
                text="VPA-Finest Commerce Academy"
              // icon="blu"
              />
            </GoogleMapReact>
          </div>
        {/* </ScrollAnimation> */}
        <Footer />
      </div>
    );
  }
}
